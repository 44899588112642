<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Jobs" to="/jobs/list/1" />

      <q-breadcrumbs-el :label="$route.params.jobId" :to="{name: 'jobView', params: { postId: $route.params.jobId}}" />
      <q-breadcrumbs-el :label="post && post.headline" :to="{name: 'jobView', params: { postId: $route.params.jobId}}" />
      <q-breadcrumbs-el label="Apply" />

    </q-breadcrumbs>

<div v-if="post">
<!-- -->

<h5 class="q-my-md">
  Respond to Ad
</h5>

<div class="alert alert-warning padded mb-lg" >
</div>

<div v-if="! user.jobseeker_info || ! user.jobseeker_info.resume" class="q-px-md q-py-sm doc-note doc-note-danger" inset>
  <div class="text-h6">You are applying to a job without a resume</div>
  <router-link :to="{name: 'accountHome'}">Manage Jobseeker Profile</router-link>
</div>

  <q-form ref="applyForm" @submit="submitApplyForm">

  <div class="q-gutter-sm">

      <div class="row">
           <div class="col-12 q-px-sm">
            <q-input
              :value="user.email"
              label="From Email"
              dense
              readonly
              :rules="[
                val => getServerError('email'),
              ]"
              @blur="clearServerError('email')"></q-input>
        </div>
      </div>

      <div class="row">
           <div class="col-12 q-px-sm">
                <q-field label="Resume" stack-label dense>
                    <template v-slot:control>
                    {{ (user.jobseeker_info && user.jobseeker_info.resume && user.jobseeker_info.resume.orig_filename) || 'None'}}
                    </template>
                    <template v-slot:append>
                      <q-btn :to="{name: 'accountHome'}" size="xs" color="primary">Upload/Replace Resume</q-btn>
                    </template>
                </q-field>
        </div>
      </div>


    <div class="row">
        <div class="col-12 q-px-sm">
          <q-input
            v-model="message"
            label="Message"
            type="textarea"
            dense
            min-height="5rem"
            :rules="[
              val => !!val || 'Field is required',
              val => getServerError('message'),
            ]"
            @blur="clearServerError('message')"></q-input>
        </div>
    </div>

    <div class="row">
      <div class="col-12 q-pa-sm">
        <q-btn type="submit" color="primary" size="md" no-caps :loading="sendingMessage">Send Message</q-btn>
      </div>
    </div>

  </div>
  </q-form>



<!-- -->
</div>
</div>
</template>

<script>
import { Notify, date } from 'quasar';
import { mapState, mapGetters } from 'vuex'
import { serverErrorMixin } from "src/mixins/server_error.mixin";
import storeRecViewMixin from "src/mixins/store_rec_view.mixin";
import ApiService from "src/services/api.service";

// import { AccountActionsActionTypes } from "../../store/module-account-actions/action-types";
// import { PostActionTypes } from "../../store/module-post/action-types";

export default {
  filters: {
    date: (val, format) => {
      if (format === undefined) {
        format = 'MMM D, YYYY';
      }
      return date.formatDate(val, format);
    },
  },
  mixins: [
    serverErrorMixin,
    storeRecViewMixin,
  ],
  data() {
    return {
      message: null,
      sendingMessage: false,
    };
  },
  computed: {
    // ...mapState('post', {
    //     loading: 'loading',
    //   }),
    // ...mapGetters('post', [
    //   'hasRecId',
    // ]),
    // ...mapState('accountActions', {
    //     isBookmarked: 'isBookmarked',
    //   }),
    // ...mapState('auth', {
    //     user: 'user',
    //   }),
    // post() {
    //   return this.$store.getters['post/getRecById'](this.$route.params.jobId);
    // }
  },
  created () {
    this.reqData();

    // TODO
    // this.$store.dispatch( 'accountActions/' + AccountActionsActionTypes.AccountActionsViewRecordRequest, { postId: this.$route.params.jobId } ).finally( () => {
    //     this.bookmarkSaving = false;
    //   });
  },
  methods: {
    // reqData (force) {
    //   if (! this.hasRecId(this.$route.params.jobId) || force) {
    //     this.$store.dispatch( 'post/' + PostActionTypes.PostGetRecordRequest, { recId: this.$route.params.postId } );
    //   }
    // },
    async submitApplyForm() {
      console.log('submit called', this.contact);
      this.sendingMessage = true;

      try {
        const apiData = await ApiService.post('/api/posts/apply/' + this.post._id, {
            job_id: this.post._id,
            email: this.user.email,
            message: this.message,
        });
        console.log("in job apply", apiData);

         Notify.create({
          position: 'top-right',
          color: 'green',
          icon: 'check',
          timeout: 2500,
          message: apiData.data.message,
          actions: [
            { icon: 'close', color: 'white' },
          ],
        });

        this.$refs.applyForm.reset();
        this.message = '';

        this.$router.push({name: 'jobView', params: {postId: this.post._id} });
        return true;
      } catch (err) {
        this.sendingMessage = false;
        console.log('ON TOP', err);
        if (err.response) {
          console.log("caught postSubmit err", err.response);
          this.processErrorData(err.response.data);

          this.$refs.applyForm.validate();
        } else {
          console.log("Error: ", err.message);
          throw err;
        }

        return false;
      } finally {
        this.sendingMessage = false;
      }
    }
  },
}
</script>
